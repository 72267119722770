<template>
  <div id="faqs">
    <faqs-add-new
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :faq-statuses="faqStatuses"
      :faq-language="faqLanguage"
      :faq-belong="faqBelong"
      @refetch-data="refetchData"
    />

    <faqs-update
      :is-update-sidebar-active.sync="isUpdateSidebarActive"
      :faq="faq"
      @refetch-data="refetchData"
    />

    <b-card class="mb-0" body-class="p-0">
      <div class="m-2">
        <b-row>
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button variant="primary" @click="isAddNewSidebarActive = true">
                <span class="text-nowrap">Add New FAQ</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <!-- language Search -->
          <b-col cols="12" lg="3" class="mt-2">
            <label>Language</label>
            <v-select
              v-model="languageSearch"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="faqLanguage"
              :clearable="false"
              class=""
            />
          </b-col>

          <!-- belong for Search -->
          <b-col ccols="12" lg="3" class="mt-2">
            <label>Belong for</label>
            <v-select
              v-model="belongSearch"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="faqBelong"
              :clearable="false"
              class=""
            />
          </b-col>
        </b-row>
      </div>
      <!--
        Faqs Table
       -->
      <b-table
        ref="refFaqsTable"
        class="position-relative"
        :items="fetchFaqs"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="isLoading ? 'Loading...' : 'No matching records found'"
      >
        <template #cell(title)="data">
          {{ data.item.title }}
        </template>
        <!-- <template #cell(slug)="data">
          {{ data.item.slug }}
        </template> -->
        <!-- <template #cell(type)="data">
          {{ data.item.type }}
        </template> -->
        <!-- <template #cell(displayOrder)="data">
          <b-form-spinbutton
            v-model="data.item.displayOrder"
            @change="changeDisplayOrder(data.item._id, $event)"
          />
        </template> -->
        <template #cell(isActive)="data">
          <b-badge :variant="`light-${resolveFaqstatusVariant(data.item.isActive)}`">{{
            data.item.isActive ? "Active" : "Disabled"
          }}</b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button size="sm" variant="warning" @click="openFaqsUpdate(data.item._id)"
            >Edit</b-button
          >
          <b-button class="ml-1" size="sm" variant="danger" @click="deleteFaqs(data.item._id)"
            >Delete</b-button
          >
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalFaqs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormSpinbutton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import useFaqsList from "./useFaqsList";
import FaqsAddNew from "./FaqsAddNew";
import FaqsUpdate from "./FaqsUpdate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { methods } from "vue2-dropzone";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormSpinbutton,
    FaqsAddNew,
    FaqsUpdate,

    vSelect,
    ToastificationContent,
  },

  setup(props, { emit }) {
    const isAddNewSidebarActive = ref(false);
    const faq = ref({});

    const {
      perPage,
      perPageOptions,
      searchQuery,
      fetchFaqs,
      tableColumns,
      resolveFaqstatusVariant,
      refFaqsTable,
      totalFaqs,
      currentPage,
      dataMeta,
      faqTypes,
      faqType,
      changeDisplayOrder,
      faqStatuses,
      faqLanguage,
      faqBelong,
      refetchData,
      isUpdateSidebarActive,
      getFaqData,
      deleteFaq,
      languageSearch,
      belongSearch,
      isLoading,
    } = useFaqsList();

    const openFaqsUpdate = async (faqID) => {
      const faqData = await getFaqData(faqID);
      faq.value = faqData;
      isUpdateSidebarActive.value = true;
    };
    const deleteFaqs = async (faqID) => {
      var result = confirm("Are you sure you want to delete this ?");
      if (result) {
        const response = await deleteFaq(faqID);

        if (typeof response !== "undefined") {
          refetchData();
        }
      }

      // alert(faqID);
      // await deleteFaq(faqID);
    };

    return {
      perPage,
      perPageOptions,
      totalFaqs,
      searchQuery,
      fetchFaqs,
      tableColumns,
      resolveFaqstatusVariant,
      refFaqsTable,
      totalFaqs,
      currentPage,
      dataMeta,
      faqTypes,
      faqType,
      changeDisplayOrder,
      isAddNewSidebarActive,
      isUpdateSidebarActive,
      faqStatuses,
      faqLanguage,
      faqBelong,
      refetchData,
      openFaqsUpdate,
      deleteFaqs,
      faq,
      languageSearch,
      belongSearch,
      isLoading,
    };
  },
  methods: {
    reset() {
      languageSearch = null;
      belongSearch = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
