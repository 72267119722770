<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isUpdateSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-update-sidebar-active', val)"
    ><template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add New Faq</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-form-input id="name" v-model="faq._id" trim hidden />

          <!-- Faq Question -->
          <validation-provider
            #default="validationContext"
            name="Question"
            rules="required"
          >
            <b-form-group label="Question" label-for="name">
              <b-form-input
                id="name"
                v-model="faq.title"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Faq Answer -->
          <validation-provider
            #default="validationContext"
            name="Answer"
            rules="required"
          >
            <b-form-group label="Answer" label-for="name">
              <b-form-textarea
                id="slug"
                v-model="faq.content"
                :state="getValidationState(validationContext)"
                rows="4"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Faq belong for -->
          <validation-provider
            #default="validationContext"
            name="belong"
            rules=""
          >
            <b-form-group label="Belong for" label-for="belong">
              <v-select
                id="belong"
                v-model="faq.belongFor"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="faqBelong"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="type"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Faq Language -->
          <validation-provider
            #default="validationContext"
            name="Language"
            rules="required"
          >
            <b-form-group
              label="Language"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="faq.language"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="faqLanguage"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="type"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Faq Type -->
          <validation-provider
            #default="validationContext"
            name="Is Active"
            rules="required"
          >
            <b-form-group
              label="Is Active"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="faq.isActive"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="faqStatuses"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="type"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit">
              Update Faq
            </b-button>
            <b-button type="button" variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template></b-sidebar
  >
</template>
<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch, computed } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import vSelect from "vue-select";
import useFaqsList from "./useFaqsList";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSpinbutton,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isUpdateSidebarActive: {
      required: true,
      type: Boolean,
    },
    faq: {
      required: true,
      type: Object,
    },
  },
  setup(props, { emit }) {
    const { faqStatuses, faqLanguage, faqBelong, updateFaq } = useFaqsList();

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    const onSubmit = async () => {
      const response = await updateFaq(props.faq);
      if (typeof response !== "undefined") {
        emit("refetch-data");
        emit("update:is-update-sidebar-active", false);
      }
    };

    return {
      refFormObserver,
      getValidationState,
      faqStatuses,
      faqLanguage,
      faqBelong,
      resetForm,
      onSubmit,
    };
  },
};
</script>
<style scoped>
.b-sidebar.sidebar-lg {
  width: 34rem;
}
</style>
