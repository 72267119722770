import { ref, watch, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default function useFaqsList() {
  const toast = useToast();
  const refFaqsTable = ref(null);

  const perPageOptions = [10, 25, 50, 100];
  const perPage = ref(10);
  const searchQuery = ref("");
  const languageSearch = ref("");
  const belongSearch = ref("");
  const totalFaqs = ref(0);
  const currentPage = ref(1);
  const isAddNewSidebarVisible = ref(false);
  const isUpdateSidebarActive = ref(false);
  const isLoading = ref(false);

  const faqStatuses = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const faqLanguage = [
    { label: "English", value: "en" },
    { label: "Arabic", value: "ar" },
  ];
  const faqBelong = [
    { label: "General", value: "general" },
    { label: "Companies", value: "companies" },
    { label: "Freelancers", value: "freelancers" },
    { label: "Investors", value: "investors" },
    { label: "Brokers", value: "brokers" },
    { label: "Advisors", value: "advisors" },
    { label: "Partners", value: "partners" },
    { label: "Verification", value: "verification" },
    { label: "Business Loans", value: "businessLoans" },
    { label: "Ideas", value: "ideas" },
    { label: "Projects", value: "projects" },
    { label: "Services", value: "services" },
    { label: "Products", value: "products" },
  ];

  const fetchFaqs = async () => {
    try {
      // console.log(languageSearch.value);
      isLoading.value = true;
      const formData = new FormData();
      formData.append("q", searchQuery.value);
      formData.append("page", currentPage.value);
      formData.append("perPage", perPage.value);
      if (languageSearch.value.value) {
        formData.append("language", languageSearch.value.value);
      }
      if (belongSearch.value.value) {
        formData.append("belongFor", belongSearch.value.value);
      }

      formData.append("type", "faq");

      const response = await axios.post("posts/search/", formData);
      // const response = await axios.get("posts/allFaqs");
      const { data } = response;
      totalFaqs.value = data.count;
      return data.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            error.response.status === 401
              ? "you dont have permissions to do this action"
              : "Error fetching faqs list",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    } finally {
      isLoading.value = false;
    }
  };

  const addFaq = async (data) => {
    try {
      const formData = new FormData();
      formData.append("title", data.name);
      formData.append("content", data.slug);
      formData.append("type", "faq");
      formData.append("belongFor", data.belong);
      formData.append("language", data.language);
      // formData.append("postFiles", "");

      //formData.append('isActive', data.isActive)
      const response = await axios.post("posts", formData);

      toast({
        component: ToastificationContent,
        props: {
          title: response.data.message,
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });

      return response;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response !== "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to save new faq",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    }
  };

  const getFaqData = async (faqID) => {
    try {
      isLoading.value = true;
      const response = await axios.get(`posts/${faqID}`);
      return response.data.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response !== "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to save new faq",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    } finally {
      isLoading.value = false;
    }
  };

  const updateFaq = async (faq) => {
    try {
      const formData = new FormData();
      formData.append("postId", faq._id);
      formData.append("title", faq.title);
      formData.append("content", faq.content);
      formData.append("belongFor", faq.belongFor);
      formData.append("language", faq.language);
      formData.append("type", "faq");
      // formData.append("isActive", faq.isActive);
      // const response = await axios.put(`faqs/${faq._id}`, formData);
      const response = await axios.put("posts", formData);

      toast({
        component: ToastificationContent,
        props: {
          title: response.data.message,
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });

      return response;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response !== "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to save new faq",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    }
  };
  const deleteFaq = async (faqID) => {
    try {
      const response = await axios.delete(`posts/${faqID}`);

      toast({
        component: ToastificationContent,
        props: {
          title: response.data.message,
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });

      return response;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response !== "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to save new faq",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    }
  };

  const tableColumns = [
    { key: "title", sortable: false, label: "Question" },
    { key: "belongFor", sortable: false, label: "Belong for" },
    { key: "language", sortable: false, label: "language" },
    { key: "isActive", sortable: false, label: "Status" },
    { key: "actions" },
  ];

  const resolveFaqstatusVariant = (status) => {
    if (status == 1) return "success";
    return "danger";
  };

  const dataMeta = computed(() => {
    const localItemsCount = refFaqsTable.value
      ? refFaqsTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFaqs.value,
    };
  });

  const refetchData = () => {
    refFaqsTable.value.refresh();
  };

  // let's watch if there is something changed in the faq
  watch(
    [perPage, searchQuery, currentPage, languageSearch, belongSearch],
    () => {
      refetchData();
    }
  );

  return {
    perPage,
    perPageOptions,
    searchQuery,
    fetchFaqs,
    tableColumns,
    resolveFaqstatusVariant,
    refFaqsTable,
    totalFaqs,
    currentPage,
    dataMeta,
    isAddNewSidebarVisible,
    isUpdateSidebarActive,
    faqStatuses,
    faqLanguage,
    faqBelong,
    addFaq,
    refetchData,
    getFaqData,
    updateFaq,
    deleteFaq,
    languageSearch,
    belongSearch,
    isLoading,
  };
}
